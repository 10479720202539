import * as React from "react";
import ReactSwipe from "react-swipe";

import Icon from "../Icon";

type CarouselProps = {
  children: React.ReactNode;
  autoSpeed?: number;
};

const Carousel = ({ children, autoSpeed }: CarouselProps) => {
  let reactSwipeEl;
  let swipeOptions = {
    continuous: true,
    speed: 800,
    auto: !!autoSpeed ? autoSpeed : false,
  };
  return (
    <div className="carousel__wrapper">
      <ReactSwipe
        className="carousel"
        swipeOptions={swipeOptions}
        ref={(el) => (reactSwipeEl = el)}
      >
        {children}
      </ReactSwipe>
      <div className="carousel__controls">
        <div
          className="carousel__next-button"
          onClick={() => reactSwipeEl.prev()}
        >
          <Icon title="Previous Arrow" size={38} />
        </div>
        <div
          className="carousel__prev-button"
          onClick={() => reactSwipeEl.next()}
        >
          <Icon title="Next Arrow" size={38} />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
